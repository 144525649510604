import { graphql } from "gatsby";
import React from "react";
import MainLayout from "../components/MainLayout";

type Props = {
  data: { markdownRemark: { html: string; frontmatter: { title: string } } };
};

export default function ({ data }: Props) {
  const post = data.markdownRemark;
  return (
    <MainLayout title={post.frontmatter.title}>
      <h3>{post.frontmatter.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </MainLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
